import React from 'react'
import { Section, Grid } from 'inno-components'
import { css } from 'aphrodite'
import Helmet from 'react-helmet'
import { withNamespaces } from 'react-i18next'

import Classes from '../../styles/classes'

const DureeDevie = ({ t }) => (
  <React.Fragment>
    <Helmet
      title={t('head.title')}
      meta={[
        {
          name: 'description',
          content: t('head.meta-description')
        }
      ]}
    />
    <Section className={css(Classes.section)}>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={16}>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('paragraphes.0')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('paragraphes.1')
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  </React.Fragment>
)

export default withNamespaces('duree-de-vie')(DureeDevie)

import React from 'react'
import { compose, pure } from 'recompose'
import { withNamespaces } from 'react-i18next'
import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'

import SecondaryLayout from '../../components/layouts/SecondaryLayout'
import DureeDeVie from '../../components/methode-raypath/DureeDeVie'

const DureeDeViePage = ({ t }) => (
  <SecondaryLayout subtitle={t('titre')} page="ComponentMethodeRaypathDureeDeVie">
    <DureeDeVie />
  </SecondaryLayout>
)

const echance = compose(
  pure,
  SetLanguageFromPage(),
  withNamespaces('duree-de-vie')
)

export default echance(DureeDeViePage)
